<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-protocols' }">{{
            $t("MENU.ITEM.PROTOCOLS.PROTOCOLS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSubcontractor"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <ProtocolForm
          v-if="!loading"
          ref="ProtocolForm"
          :form="form"
        ></ProtocolForm>
      </template>

      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSubcontractor"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_PROTOCOL } from "@/modules/protocols/store/protocol.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import ProtocolForm from "./ProtocolForm.vue";

import KTCard from "@/view/content/Card.vue";
import { mapState } from "vuex";

export default {
  name: "ProtocolAdd",
  components: {
    KTCard,
    SaveButtonDropdown,
    ProtocolForm,
  },
  props: {
    bol: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      errors: {},
      form: {
        assignorId: null,
        locationId: null,
        dateFrom: null,
        dateTo: null,
        name: null,
        protocolDate: new Date().toISOString().slice(0, 10),
        billOfLadings: [],
      },
    };
  },
  mounted() {
    if (this.bol) {
      this.setDefaultBolValues();
    }
    this.loading = false;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.PROTOCOLS.PROTOCOLS"),
        route: { name: "list-protocols" },
      },
      { title: this.$i18n.t("LABELS.ADD_NEW") },
    ]);
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    onSave(next) {
      let vm = this;

      this.$refs.ProtocolForm.$v.form.$touch();
      if (this.$refs.ProtocolForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      this.$store
        .dispatch(SAVE_PROTOCOL, this.form)
        .then((data) => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-protocols",
              params: { id: itemEdit.id },
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-protocols" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-protocols" });
          }
        })
        .catch((response) => {
          this.errors = this.$errors.format(response.data.errors);
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
    setDefaultBolValues() {
      this.form.assignorId = this.bol.assignorId;
      this.form.locationId = this.bol.locationId;
      this.form.dateFrom = this.bol.bolDate;
      this.form.dateTo = this.bol.bolDate;
      this.form.billOfLadings.push(this.bol);
    },
  },
};
</script>
